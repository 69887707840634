import React from "react";
import { FiHome } from "react-icons/fi";
import { Link, useNavigate } from "react-router-dom";
import home from "../assets/home.png";
import { IoMdArrowRoundBack } from "react-icons/io";

const PageFrame = ({ children, current_page }) => {
  const navigate = useNavigate();
  return (
    <div className="flex justify-center">
      <div className="flex max-w-[90%] xl:max-w-[80%] flex-col self-center w-full rounded-[10px] overflow-hidden border-[#07152433] border-[1px] mt-3 md:mt-8">
        <div className="bg-[#103935] flex flex-row  gap-3 justify-between items-center p-4 md:py-5 md:px-6">
          <div className="flex gap-2 md:gap-3 items-center">
            {/* <svg
              width="20"
              height="22"
              viewBox="0 0 20 22"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M7 21V11H13V21M1 8L10 1L19 8V19C19 19.5304 18.7893 20.0391 18.4142 20.4142C18.0391 20.7893 17.5304 21 17 21H3C2.46957 21 1.96086 20.7893 1.58579 20.4142C1.21071 20.0391 1 19.5304 1 19V8Z"
                stroke="white"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
            </div> */}
            {/* <img src={home} className="h-[10%] w-[10%] object-contain" /> */}
            <div
              className="flex items-center gap-2 cursor-pointer"
              onClick={() => navigate(-1)}
            >
              <IoMdArrowRoundBack
                color="#fff"
                size={25}
                // className="cursor-pointer "
              />
              <p className="font-inter text-clamp-lg font-medium text-[#FFF]">
                Back
              </p>
            </div>
            <div className="font-inter text-clamp-lg text-[#fff]">/</div>
            <div className="font-inter text-clamp-lg text-[#ffffff50]">
              {current_page}
            </div>
          </div>
        </div>
        {children}
      </div>
    </div>
  );
};

export default PageFrame;
