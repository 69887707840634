import React, { useEffect } from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import { notification } from "antd";
import { store } from "./redux/store";
import { fetchCategories, setMy_Theme } from "./redux/slice/userSlice";
import "./App.css";
// Components
import Layout from "./components/Layout";
import NotFound from "./pages/NotFound";
import Login from "./pages/auth/Login";
import ReactGA from "react-ga4";
import { gtag } from "./utils/gtag";
import SubscriptionPage from "./pages/home/SubscriptionPage";

// Lazy-loaded Pages
const HomePage = React.lazy(() => import("./pages/HomePage"));
const TemplateDetailPage = React.lazy(() =>
  import("./pages/template/TemplateDetailPage")
);

const PricingCard = React.lazy(() => import("./pages/PricingCard"));
const Profile = React.lazy(() => import("./pages/Profile"));
const MyFolders = React.lazy(() => import("./pages/MyFolders"));
const RSVP = React.lazy(() => import("./pages/RSVP"));

const TermsAndConditions = React.lazy(() =>
  import("./pages/TermsAndConditions")
);
const FAQ = React.lazy(() => import("./pages/FAQ"));
const PrivacyPolicy = React.lazy(() => import("./pages/PrivacyPolicy"));
const RefundPolicy = React.lazy(() => import("./pages/RefundPolicy"));
const Checkout = React.lazy(() => import("./pages/Checkout"));
const ThemeEdit = React.lazy(() => import("./pages/template/ThemeEdit"));

export const openNotificationWithIcon = (type, title, message) => {
  notification[type]({
    message: title,
    description: message,
  });
};

const App = () => {
  const [api, contextHolder] = notification.useNotification();

  useEffect(() => {
    // Fetch categories and theme only once on mount
    const initializeApp = async () => {
      try {
        await store.dispatch(fetchCategories());
        store.dispatch(setMy_Theme({}));
      } catch (error) {
        openNotificationWithIcon(
          "error",
          "Initialization Error",
          error.message
        );
      }
    };
    initializeApp();
  }, []);

  useEffect(() => {
    const handleKeyDown = (e) => {
      if (
        e.key === "PrintScreen" ||
        (e.ctrlKey && (e.key === "s" || e.key === "p"))
      ) {
        e.preventDefault();
        alert("Screenshot and print are disabled!");
      }
    };

    window.addEventListener("keydown", handleKeyDown);

    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, []);
  // ReactGA.initialize("G-9F5XFVJ5GB");
  ReactGA.initialize("G-9F5XFVJ5GB");

  useEffect(() => {
    const pagePath = window.location.pathname;
    gtag("config", "G-9F5XFVJ5GB", { page_path: pagePath });
  }, [window.location.pathname]);

  return (
    <div className="no-select overflow-hidden">
      {contextHolder}
      {/* <ScreenshotPrevention> */}
      <React.Suspense>
        <Routes>
          <Route path="/" element={<Layout />}>
            <Route index element={<HomePage />} />
            <Route path="*" element={<NotFound />} />
            <Route path="template/:slug" element={<TemplateDetailPage />} />
            <Route path="template/:slug/edit" element={<ThemeEdit />} />

            <Route path="subscription" element={<SubscriptionPage />} />

            <Route path="profile" element={<Profile />} />
            <Route path="my-order" element={<MyFolders />} />
            <Route path="terms-condition" element={<TermsAndConditions />} />
            <Route path="faq" element={<FAQ />} />
            <Route path="privacy-policy" element={<PrivacyPolicy />} />
            <Route path="refund-policy" element={<RefundPolicy />} />
            <Route path="rsvp" element={<RSVP />} />
            <Route path="checkout" element={<Checkout />} />
            {/* <Route path="transactions" element={<Transactions />} /> */}
          </Route>
        </Routes>
      </React.Suspense>
      <Login />
      {/* </ScreenshotPrevention> */}
    </div>
  );
};

export default App;
