import React from "react";
import PageFrame from "../../components/PageFrame";
import { planData } from "../../utils/data";
import { useNavigate } from "react-router-dom";

const PlanCard = ({ plan, index }) => {
  const navigate = useNavigate();

  const handleClick = () => {
    if (plan.action === "Get Started") {
      navigate("/");
    } else if (plan.action === "Buy Now") {
      navigate("/checkout", { state: plan });
    }
  };

  return (
    <div
      className="border p-2 gap-1 flex flex-col items-center w-52 min-h-[450px] flex-1"
      style={{
        backgroundColor: plan?.bgColor,
        borderColor: plan?.borderColor,
      }}
    >
      {index == 0 || (
        <h2 className="text-2xl font-medium text-[#103935] flex">
          ₹
          <h2 className="line-through">
            {index === 0 ? "Free" : `${plan?.price1}`}
          </h2>
        </h2>
      )}
      <h2 className="text-3xl font-extrabold text-[#103935]">
        {index === 0 ? "Free" : `₹${plan?.price}`}
      </h2>
      <p className="text-3xl text-[#103935] font-normal">{plan?.planName}</p>
      <div className="w-full h-[100px] flex items-center justify-center">
        <img
          src={plan?.image}
          alt={plan?.subtitle}
          className="w-[60%] h-[60%] object-contain"
        />
      </div>
      <h3 className="text-[60px] font-extrabold text-[#103935]">
        {plan?.no_of_theme}
      </h3>

      {index == 0 ? (
        <p className="text-2xl text-[#103935] text-center font-inter font-bold">
          Free theme credit{" "}
          {/* <p className="text-[16px] text-[#FF9500] text-center font-inter font-bold">
            (with watermark)
          </p> */}
        </p>
      ) : (
        <p className="text-2xl text-[#103935] text-center font-inter font-bold">
          {plan?.no_of_theme == 1 ? "Paid theme credit" : "Paid theme credits"}
        </p>
      )}

      <div className="flex flex-1"></div>
      <button
        onClick={handleClick}
        className={`mt-4 px-5 py-2 rounded-lg font-medium border-[#103935] border-[0.76px] text-[15px] ${
          index === 0 ? "text-[#103935] bg-[#FFF]" : "text-[#FFF] bg-[#103935]"
        }`}
      >
        {plan?.action}
      </button>
    </div>
  );
};

const SubscriptionPage = () => {
  return (
    <PageFrame current_page={"Subscription"}>
      <div className="overflow-auto max-w-full max-h-[650px] mt-4">
        <table className="w-full border-collapse ">
          <thead>
            <tr className="">
              <th className="border border-gray-300 p-3 text-lg ">Features</th>
              {planData.map((plan, index) => (
                <th key={index} className="border border-gray-300">
                  <PlanCard plan={plan} index={index} />
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            <tr className="text-center">
              <td className="border border-gray-300 p-3 font-bold">
                Watermark
              </td>
              {planData.map((plan, index) => (
                <td
                  key={index}
                  className={`border border-gray-300 p-3 font-bold ${
                    plan?.watermark == "Yes" ? "text-[#f00]" : "text-green-700"
                  }`}
                >
                  {plan.watermark}
                </td>
              ))}
            </tr>
            <tr className="text-center">
              <td className="border border-gray-300 p-3 font-bold">
                Ads on Download
              </td>
              {planData.map((plan, index) => (
                <td
                  key={index}
                  className={`border border-gray-300 p-3 font-bold  ${
                    plan?.ads == "Yes" ? "text-[#f00]" : "text-green-700"
                  }`}
                >
                  {plan.ads}
                </td>
              ))}
            </tr>
            <tr className="text-center">
              <td className="border border-gray-300 p-3 font-bold min-w-40">
                Download Availability
              </td>
              {planData.map((plan, index) => (
                <td
                  key={index}
                  className="border border-gray-300 p-3 font-bold"
                >
                  {plan.days}
                </td>
              ))}
            </tr>
          </tbody>
        </table>
      </div>
    </PageFrame>
  );
};

export default SubscriptionPage;
