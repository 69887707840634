import { useCallback, useRef, useState } from "react";
import React, { useEffect, memo } from "react";
import crypto from "crypto-js";
import { Oval } from "react-loader-spinner";
import { Link, useBlocker } from "react-router-dom";

export const HeadingText = ({ children, className }) => {
  return (
    <div
      className={`text-clamp-heading font-baskerville font-normal text-custom-text text-center  ${className}`}
    >
      {children}
    </div>
  );
};
export const TitleText = ({ children, className }) => {
  return (
    <div
      className={`font-inter font-normal text-custom-text text-center text-clamp-base16 ${className}`}
    >
      {children}
    </div>
  );
};
export const MediumText = ({ children, className }) => {
  return (
    <div
      className={`font-inter font-normal text-white text-clamp-base16 ${className}`}
    >
      {children}
    </div>
  );
};
export const FooterLinkText = ({ children, className, to }) => {
  return (
    <Link
      to={to}
      className={`font-inter font-normal text-[#ffffff80] text-clamp-sm cursor-pointer ${className}`}
    >
      {children}
    </Link>
  );
};

export const Button = ({ title, onClick, className, bg, loading = false }) => {
  return (
    <button
      onClick={onClick}
      disabled={loading}
      className={`flex justify-center border-[1px] border-[#CD8E42] h-[30px] md:h-[38px] rounded-[5px] md:rounded-[10px] items-center px-[20px] text-clamp-sm text-[#071524] font-inter font-normal ${className} ${
        bg ? bg : "bg-gradient-to-r from-[#FFF090] to-[#FABF55]"
      }`}
    >
      {loading ? (
        <>
          <Oval
            visible={true}
            height="30"
            width="30"
            color="#FF9500"
            ariaLabel="oval-loading"
            wrapperStyle={{}}
            secondaryColor="#FF950055"
            wrapperClass=""
            strokeWidth={6}
          />
        </>
      ) : (
        title
      )}
    </button>
  );
};

export const RadioBtn = ({
  children,
  title,
  className,
  active = false,
  onClick,
  right,
  btnclassName,
  textclassName,
}) => {
  return (
    <div className={`flex gap-2 items-center ${className}`}>
      <div className="flex min-w-[20px]">
        <div
          onClick={onClick}
          className={`h-[18px] md:h-[20px] w-[18px] md:w-[20px] md:min-w-[20px] border-custom-primary rounded-full cursor-pointer ${
            active
              ? "border-[4px] md:border-[6px]"
              : "border-[1.5px] md:border-[2px]"
          } ${btnclassName}`}
        />
      </div>
      <div
        className={`font-inter font-normal text-[#071524CC] w-full text-clamp-base16 ${textclassName}`}
      >
        {title}
      </div>
      {right && <div className="flex">{right}</div>}
    </div>
  );
};

export const Input = ({
  label,
  placeholder,
  value,
  onChange,
  type,
  name,
  className,
  maxLength,
}) => {
  return (
    <div className={`flex flex-col mt-4 ${className}`}>
      {label && (
        <label className="text-[#fff] font-inter text-clamp-sm">{label}</label>
      )}
      <input
        className="bg-[#fff] py-[10px] md:py-[15px] px-3 mt-1 rounded-[5px] md:rounded-[10px] flex-1 font-inter text-clamp-sm text-[#071524] placeholder:text-[#333333] focus:outline-none"
        placeholder={placeholder}
        value={value}
        onChange={onChange}
        type={type}
        name={name}
        maxLength={maxLength}
      />
    </div>
  );
};
export const Input1 = ({ label, placeholder, value, onChange }) => {
  const [inputValue, setInputValue] = useState("");
  const [inputWidth, setInputWidth] = useState("auto");
  const handleChange = (e) => {
    setInputValue(e.target.value);
    setInputWidth(`${e.target.value.length * 17}px`); // Adjust multiplier based on font size and padding
  };

  return (
    <input
      type="text"
      value={inputValue}
      onChange={handleChange}
      style={{ width: inputWidth }}
      className="bg-transparent p-2 rounded-[5px] border-dashed border-[1px] border-[#fff] font-baskerville font-medium text-[30px] text-[#FABF55] placeholder:text-[#333333] outline-none"
      placeholder={placeholder}
      // value={value}
    />
  );
};

export const AutoResizableInput = () => {
  const inputRef = useRef(null);
  const [inputValue, setInputValue] = useState("");
  const [inputWidth, setInputWidth] = useState(0);

  const handleChange = (e) => {
    setInputValue(e.target.value);
    setInputWidth(inputRef.current.scrollWidth);
  };

  const handleKeyUp = () => {
    setInputWidth(inputRef.current);
  };

  return (
    <input
      ref={inputRef}
      type="text"
      value={inputValue}
      onChange={handleChange}
      onKeyUp={handleKeyUp}
      style={{ width: `${inputWidth}px` }}
      className="border border-gray-300 rounded px-2 py-1 focus:outline-none focus:border-blue-500"
      // placeholder="Type something..."
    />
  );
};

export const generateSecretHash = async (username) => {
  const hmac = crypto.algo.HMAC.create(
    crypto.algo.SHA256,
    process.env.REACT_APP_AWS_APP_CLIENT_SECRET
  );
  hmac.update(username + process.env.REACT_APP_AWS_APP_CLIENT_ID);
  const hash = hmac.finalize();
  return hash.toString(crypto.enc.Base64);
};

export const PageLoader = () => {
  return (
    <div className="w-full flex p-8 items-center justify-center overflow-hidden self-center">
      <iframe
        src="/assets/order-pending.html"
        title="Animated Content"
        height={120}
        width={120}
        // style={{ border: "none", objectFit: "contain", overflow: "hidden" }}
        // className="relative self-center flex overflow-hidden"
      />

      {/* <img
        src={loader_gif}
        alt="Loading GIF"
        className="h-[10%] w-[10%] object-contain rounded-full"
      /> */}
    </div>
  );
};

export const PageLoader1 = memo(({ loading }) => {
  useEffect(() => {
    if (loading) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "auto";
    }

    return () => {
      document.body.style.overflow = "auto"; // Cleanup on unmount
    };
  }, [loading]);

  if (!loading) return null; // Prevent unnecessary rendering

  return (
    <div className="fixed inset-0 flex items-center justify-center bg-white bg-opacity-5 z-20">
      <iframe
        src="/assets/order-pending.html"
        title="Animated Content"
        className="w-[120px] h-[120px]"
      />
    </div>
  );
});
